import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormLabel,
  Typography,
  Grid,
  FormControlLabel,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ITALIAN_ISO_CODE } from "../utils/LanguagesOptions";

const FormLingue = ({
  lista,
  expanded,
  handleChange,
  selectedWebLanguages,
  setSelectedWebLanguages,
  selectedAxLanguages,
  setSelectedAxLanguages,
}) => {
  const { t } = useTranslation();
  const accordionId = "languages";
  const theme = useTheme();

  const handleChangeAxLanguage = (event) => {
    const { name, checked } = event.target;

    setSelectedAxLanguages((prev) => {
      if (checked) {
        return [...prev, name];
      }
      return prev.filter((language) => language != name);
    });
  };

  const handleChangeWebLanguage = (event) => {
    const { name, checked } = event.target;

    setSelectedWebLanguages((prev) => {
      if (checked) {
        return [...prev, name];
      }
      return prev.filter((language) => language != name);
    });
  };

  function renderAxLanguages() {
    if (lista.axLanguagesOptions) {
      return lista.axLanguagesOptions.map(({ key, label }) => {
        return (
          <FormControlLabel
            key={key}
            label={label}
            control={
              <Checkbox
                id={`linguaAxForm-${key}`}
                name={key}
                defaultChecked={key === ITALIAN_ISO_CODE}
                onChange={(event) => handleChangeAxLanguage(event)}
              />
            }
          />
        );
      });
    } else {
      return {};
    }
  }

  function renderLanguages() {
    if (lista.languagesOptions) {
      return lista.languagesOptions.map(({ key, label }) => {
        return (
          <Box sx={{ minWidth: "260px" }}>
            <FormControlLabel
              key={key}
              label={label}
              control={
                <Checkbox
                  id={`linguaWebForm-${key}`}
                  name={key}
                  label={label}
                  defaultChecked={key === ITALIAN_ISO_CODE}
                  onChange={(event) => handleChangeWebLanguage(event)}
                />
              }
            />
          </Box>
        );
      });
    } else {
      return {};
    }
  }

  return (
    <Accordion
      expanded={expanded === accordionId}
      onChange={handleChange(accordionId)}
      className={
        selectedAxLanguages.length === 0 || selectedWebLanguages.length === 0
          ? "accordionError"
          : ""
      }
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {selectedAxLanguages.length === 0 ||
        selectedWebLanguages.length === 0 ? (
          <h4 style={{ color: theme.palette.error.main }}>
            {t("labels.languages")}*
          </h4>
        ) : (
          <h4> {t("labels.languages")}</h4>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container minHeight={"320px"}>
          <Grid item xs={6} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }} id="linguaAxForm">
              <FormLabel>
                <h4>{t("labels.AXLanguage")}</h4>
              </FormLabel>
              <FormLabel>
                <Typography fontWeight="bold">
                  {t("labels.AXLanguageDesc")}
                </Typography>
              </FormLabel>
              {renderAxLanguages()}
              <Box sx={{ minWidth: "260px" }}>
                {selectedAxLanguages.length === 0 && (
                  <FormHelperText error>
                    {t("labels.errorForLanguageSelection")}
                  </FormHelperText>
                )}
              </Box>
            </FormGroup>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }} id="linguaWebForm">
              <FormLabel>
                <h4>{t("labels.webLanguage")}</h4>
              </FormLabel>
              <FormLabel>
                <Typography fontWeight="bold">
                  {t("labels.webLanguageDesc")}
                </Typography>
              </FormLabel>
              {renderLanguages()}
              {selectedWebLanguages.length === 0 && (
                <FormHelperText error>
                  {t("labels.errorForLanguageSelection")}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FormLingue;
