export const IMAGESIZE_SMALL = { Width: 1000, Height: 1000 }
export const IMAGESIZE_MEDIUM = { Width: 2400, Height: 2400 }
export const IMAGESIZE_LARGE = { Width: 4000, Height: 4000 }

export const getImageSizeById = (id) => {
    switch (id) {
        case "0":
            return IMAGESIZE_SMALL;
        case "1":
            return IMAGESIZE_MEDIUM;
        case "2":
            return IMAGESIZE_LARGE;
        default:
            throw new Error("id was not a valid value")
    }
}