import React, { useEffect, useState } from "react";
import FormLingue from "./FormLingue";
import FormMercati from "./FormMercati";
import FormListini from "./FormListini";
import FormGestioneImmagini from "./FormGestioneImmagini";
import FormAltro from "./FormAltro";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Box, Chip } from "@mui/material";
import { ITALIAN_ISO_CODE } from "../utils/LanguagesOptions";

const Parameters = ({
  lista,
  inputLavorazione,
  handleInputLavorazione,
  selectedPriceList,
  setDisableButtonIfMandatoryOptionsAreEmpty,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState("");
  const [selectedWebLanguages, setSelectedWebLanguages] = useState([
    ITALIAN_ISO_CODE,
  ]);
  const [selectedAxLanguages, setSelectedAxLanguages] = useState([
    ITALIAN_ISO_CODE,
  ]);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (
      selectedAxLanguages.length === 0 ||
      selectedWebLanguages.length === 0 ||
      inputLavorazione.length === 0
    ) {
      setDisableButtonIfMandatoryOptionsAreEmpty(true);
    } else {
      setDisableButtonIfMandatoryOptionsAreEmpty(false);
    }
  }, [inputLavorazione, selectedAxLanguages, selectedWebLanguages]);

  return (
    <Grid container direction="column">
      <Grid item xs={12} marginBottom={3}>
        <h1>{t("labels.parameters")}</h1>
      </Grid>
      <Grid item xs={12} marginBottom={4} paddingLeft="10%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
            width: "80%",
            alignSelf: "center",
          }}
        >
          <Chip label="YYYY-MM-DD-hh-mm-ss" sx={{ flex: 1 }} />
          <TextField
            sx={{ flex: 5 }}
            id="inputLavorazioneForm"
            label={t("placeholders.insertJobName")}
            variant="standard"
            value={inputLavorazione}
            onChange={handleInputLavorazione}
            error={inputLavorazione.length === 0}
          />
        </Box>
      </Grid>
      <Grid item xs={12} marginBottom={4}>
        <FormLingue
          lista={lista}
          handleChange={handleAccordionChange}
          expanded={expanded}
          selectedAxLanguages={selectedAxLanguages}
          setSelectedAxLanguages={setSelectedAxLanguages}
          selectedWebLanguages={selectedWebLanguages}
          setSelectedWebLanguages={setSelectedWebLanguages}
        />
        <FormListini
          lista={lista}
          handleChange={handleAccordionChange}
          expanded={expanded}
          selectedPriceList={selectedPriceList}
        />
        <FormMercati
          lista={lista}
          handleChange={handleAccordionChange}
          expanded={expanded}
        />
        <FormGestioneImmagini
          handleChange={handleAccordionChange}
          expanded={expanded}
        />
        <FormAltro
          lista={lista}
          handleChange={handleAccordionChange}
          expanded={expanded}
        />
      </Grid>
    </Grid>
  );
};

export default Parameters;
