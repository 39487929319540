import React from 'react'
import { useTranslation } from 'react-i18next';
import { Checkbox, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormLabel, Typography, Grid, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FormMercati = ({ lista, expanded, handleChange }) => {
    const { t } = useTranslation();
    const accordionId = "markets";

    function renderMarketOptions() {
        if (lista.marketsOptions) {
            return (
                lista.marketsOptions.map(({ key, label }) => {
                    return <FormControlLabel key={key} label={label} control={<Checkbox id={`mercatiWebForm-${key}`} name={key} />} />
                })
            )
        } else {
            return ({})
        }
    }
    return (
        <Accordion expanded={expanded === accordionId} onChange={handleChange(accordionId)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}><h4>{t("labels.marketsListHeader")}</h4></AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <FormGroup md={{ offset: 1 }} id="mercatiWebForm">
                            <FormLabel><h4>{t("labels.markets")}</h4></FormLabel>
                            <FormLabel><Typography fontWeight="bold">{t("labels.marketsDesc")}</Typography></FormLabel>
                            {
                                renderMarketOptions()
                            }
                        </FormGroup>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default FormMercati
