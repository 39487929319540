import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, RadioGroup, FormControlLabel, Radio, TextField, MenuItem  } from '@mui/material';
import { SkuSelectionOption } from '../utils/SkuSelectionOption';

const SkuList = ({skuSelection,
    skuSelectionHandler, 
    collections, 
    priceList,
    selectedCollections, 
    selectedPriceList,
    selectedCollectionsHandler,
    priceListHandler,
    disableHandler,
    disablePriceListHandler,
    handleSkuSelected,
    openSelect,
    handleSelectOpen,
    handleSelectClose,
}) => {
    const { t } = useTranslation();
    
    return(
        <Grid container direction="column" paddingLeft="20%">
            <Grid item xs={12}>
                <RadioGroup name="group_sku_selection" 
                            onChange={(event) => skuSelectionHandler(event.target.value)} 
                            defaultValue={SkuSelectionOption.SKU}
                            value={skuSelection}>
                    <FormControlLabel value={SkuSelectionOption.SKU} control={<Radio />} label={t("labels.skuByHand")}/>
                    <FormControlLabel value={SkuSelectionOption.COLLECTION}  control={<Radio />} label={t("labels.skuFromCollections")} />
                    <FormControlLabel value={SkuSelectionOption.PRICELIST}  control={<Radio />} label={t("labels.skuFromPriceList")} />  
                    <FormControlLabel value={SkuSelectionOption.EAN} control={<Radio />} label={t("labels.skuFromEAN")}/>
                    <FormControlLabel value={SkuSelectionOption.UPC}  control={<Radio />} label={t("labels.skuFromUPC")} />                   
                </RadioGroup> 
            </Grid>
            <Grid item xs={12} >
                { skuSelection === SkuSelectionOption.SKU && 
                    <TextField
                        id="outlined-multiline-static"
                        name="numeroSku"
                        label={t("placeholders.insertSkus")}
                        multiline
                        style={{ width: "60%"}}
                        rows={10}
                        onChange={handleSkuSelected}
                    />
                } 
                {  skuSelection === SkuSelectionOption.PRICELIST &&
                    <TextField
                        id="outlined-select-currency"
                        select 
                        label={t("placeholders.selectPriceList")}
                        SelectProps={{
                          multiple: true,
                          value: selectedPriceList,
                          onChange: priceListHandler,
                          sx:{maxWidth: 360, minWidth: 360},
                          open: openSelect,
                          onOpen: handleSelectOpen,
                          onClose: handleSelectClose
                        }}
                    >
                    {priceList?.map((option) => (
                        <MenuItem 
                          key={option.key}
                          value={option.key}
                          disabled={disablePriceListHandler(option.key)}
                        >
                         {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                }      
                {  skuSelection === SkuSelectionOption.COLLECTION &&
                    <TextField
                        id="outlined-select-currency"
                        select 
                        label={t("placeholders.selectSkuCollection")}
                        SelectProps={{
                          multiple: true,
                          value: selectedCollections,
                          onChange: selectedCollectionsHandler,
                          sx:{maxWidth: 360, minWidth: 360},
                          open: openSelect,
                          onOpen: handleSelectOpen,
                          onClose: handleSelectClose
                        }}
                    >
                    {collections?.map((option) => (
                        <MenuItem 
                          key={option.value}
                          value={option.value}
                          disabled={disableHandler(option.value)}
                        >
                         {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                }         
                { skuSelection === SkuSelectionOption.EAN && 
                    <TextField
                        id="outlined-multiline-static"
                        name="numeroEAN"
                        label={t("placeholders.insertEAN")}
                        multiline
                        style={{ width: "60%"}}
                        rows={10}
                        onChange={handleSkuSelected}
                    />
                }
                { skuSelection === SkuSelectionOption.UPC && 
                    <TextField
                        id="outlined-multiline-static"
                        name="numeroUPC"
                        label={t("placeholders.insertUPC")}
                        multiline
                        style={{ width: "60%"}}
                        rows={10}
                        onChange={handleSkuSelected}
                    />
                }
            </Grid>
        </Grid>
        
    )
}

export default SkuList;