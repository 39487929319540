import React from 'react'
import { FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next';


export default function ImageSizeForm(disableFormImageWhenSelectAllImages, selectedSize, setSelectedSize) {

    const handleSelectedSize= (event) => {
        setSelectedSize(event.target.value)
    };

    const { t } = useTranslation()
    return (
        <FormGroup>
            <FormLabel><h4>{t("labels.imageSize")}</h4></FormLabel>
            <RadioGroup name='image-size-select-radio-group' value={selectedSize} onChange={(event) => handleSelectedSize(event)}>
                <FormControlLabel label={t("labels.SmallImage")} value={"0"} control={<Radio />} disabled={disableFormImageWhenSelectAllImages}/>
                <FormControlLabel label={t("labels.MediumImage")} value={"1"} control={<Radio />} />
                <FormControlLabel label={t("labels.LargeImage")} value={"2"} control={<Radio />} disabled={disableFormImageWhenSelectAllImages}/>
            </RadioGroup>
        </FormGroup>
    );
}