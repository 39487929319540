import { useOktaAuth } from '@okta/okta-react'
import { useEffect } from 'react'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Outlet } from 'react-router-dom'
import LoadWaiter from '../components/LoadWaiter'

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    if (!authState) {
      return
    }
    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      if (originalUri.includes('kering')) {
        oktaAuth.setOriginalUri('/')
      } else {
        oktaAuth.setOriginalUri(originalUri)
      }
      oktaAuth.signInWithRedirect()
    }
  }, [oktaAuth, authState])

  if (!authState || !authState?.isAuthenticated)
    return (
      <LoadWaiter loading={true}>
        <Outlet />
      </LoadWaiter>
    )

  return <Outlet />
}
