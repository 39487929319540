import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormLabel,
  Typography,
  Grid,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FormListini = ({ lista, expanded, handleChange, selectedPriceList }) => {
  const { t } = useTranslation();
  const accordionId = "prices";

  const [checkedStates, setCheckedStates] = useState(() => {
    return lista.listingOptions.reduce((acc, { key }) => {
      acc[key] = key == selectedPriceList;
      return acc;
    }, {});
  });

  function handleOnChangeChecked(event) {
    const { name, checked } = event.target;
    setCheckedStates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  }

  useEffect(() => {
    const initialCheckedStates = lista.listingOptions.reduce((acc, { key }) => {
      acc[key] = key === selectedPriceList;
      return acc;
    }, {});

    setCheckedStates(initialCheckedStates);
  }, [selectedPriceList, lista.listingOptions]);

  function renderListingOptions() {
    const ecommerceOptions = [];
    const otherOptions = [];

    if (lista.listingOptions) {
      lista.listingOptions.forEach(({ key, label }) => {
        const isChecked = checkedStates[key];

        const option = (
          <FormControlLabel
            key={key}
            label={label}
            control={
              <Checkbox
                id={`listiniForm-${key}`}
                name={key}
                checked={isChecked}
                onChange={(event) => handleOnChangeChecked(event)}
              />
            }
          />
        );

        if (label.includes("ECommerce")) {
          ecommerceOptions.push(option);
        } else {
          otherOptions.push(option);
        }
      });
    }

    return { ecommerceOptions, otherOptions };
  }

  return (
    <Accordion
      expanded={expanded === accordionId}
      onChange={handleChange(accordionId)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h4>{t("labels.priceListHeader")}</h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={6} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }} id="listiniForm">
              <FormLabel>
                <h4>{t("labels.ecommercePriceList")}</h4>
              </FormLabel>
              <FormLabel>
                <Typography fontWeight="bold">
                  {t("labels.priceListDesc")}
                </Typography>
              </FormLabel>
              {renderListingOptions(selectedPriceList).ecommerceOptions}
            </FormGroup>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="center">
            <FormGroup md={{ offset: 1 }} id="listiniForm">
              <FormLabel>
                <h4>{t("labels.priceList")}</h4>
              </FormLabel>
              <FormLabel>
                <Typography fontWeight="bold">
                  {t("labels.priceListDesc")}
                </Typography>
              </FormLabel>
              {renderListingOptions(selectedPriceList).otherOptions}
            </FormGroup>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FormListini;
