import React, { useState, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormLabel,
  RadioGroup,
  Grid,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import ImageSizeForm from "./ImageSizeForm";

const FormGestioneImmaginiWeb = ({ expanded, handleChange }) => {
  const { t } = useTranslation();
  const [
    disableFormImageWhenSelectAllImages,
    setDisableFormImageWheneSelectAllImages,
  ] = useState(false);
  const [selectedImageExcelOptions, setSelectedImageExcelOptions] =
    useState("0");
  const [selectedImageZipOptions, setSelectedImageZipOptions] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState("0");
  const [selectedPreview, setSelectedPreview] = useState("1");
  const [selectedSize, setSelectedSize] = useState(0);
  const accordionId = "imageManagement";

  const [downloadFromWeb, setDownloadFromWeb] = useState(false);

  const handleSelectedFormat = (event) => {
    setSelectedFormat(event.target.value);
  };

  const handleRadioZipOptions = (event) => {
    const value = event.target.value;

    if (selectedImageZipOptions == value) {
      setSelectedImageZipOptions(null);
      setDownloadFromWeb(false);
    } else {
      setSelectedImageZipOptions(value);
      setDownloadFromWeb(true);
    }
  };

  useEffect(() => {
    if (selectedImageZipOptions === "1") {
      setDisableFormImageWheneSelectAllImages(true);
      setSelectedFormat("1");
      setSelectedSize(1);
    } else {
      setDisableFormImageWheneSelectAllImages(false);
      setSelectedFormat("0");
      setSelectedSize(0);
    }
  }, [selectedImageExcelOptions, selectedImageZipOptions]);

  return (
    <Accordion
      expanded={expanded === accordionId}
      onChange={handleChange(accordionId)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h4>{t("labels.images")}</h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          display="flex"
          justifyContent="right"
          paddingLeft={5}
          paddingRight={5}
        >
          <Grid item xs={3.5} columnSpacing={1} flexDirection="column">
            <FormLabel>
              <h3>{"EXCEL"}</h3>
            </FormLabel>

            <RadioGroup
              name="group_select_preview_image_excel"
              value={selectedPreview}
              onChange={(event) => {
                setSelectedPreview(event.target.value);
              }}
            >
              <FormControlLabel
                label={t("labels.fileImagePreview")}
                value="0"
                control={<Radio />}
              />
              <FormControlLabel
                label={t("labels.cloudPreview")}
                value="1"
                control={<Radio />}
              />
            </RadioGroup>
            <Box my={4} />
            <RadioGroup
              name="group_select_link_image_excel"
              value={selectedImageExcelOptions}
              onChange={(event) => {
                setSelectedImageExcelOptions(event.target.value);
              }}
            >
              <FormControlLabel
                label={t("labels.mainImageLink")}
                value="0"
                control={<Radio />}
              />
              <FormControlLabel
                label={t("labels.allImagesLink")}
                value="1"
                control={<Radio />}
              />
              <FormControlLabel
                label={t("labels.mainImageInCloud")}
                value="2"
                control={<Radio />}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={5}>
            <FormLabel>
              <h3>{"DOWNLOAD ZIP"}</h3>
            </FormLabel>
            <RadioGroup
              name="group_select_download_image_zip"
              value={selectedImageZipOptions}
              onClick={(event) => handleRadioZipOptions(event)}
            >
              <FormControlLabel
                label={t("labels.downloadMainImage")}
                value="0"
                control={<Radio />}
              />
              <FormControlLabel
                label={t("labels.downloadAllIamges")}
                value="1"
                control={<Radio />}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={3.5}>
            <FormGroup id="Imageformat">
              <FormLabel>
                <h4>{t("labels.format")}</h4>
              </FormLabel>
              <RadioGroup
                name="group_image_extension"
                value={selectedFormat}
                onChange={(event) => handleSelectedFormat(event)}
              >
                <FormControlLabel
                  label={t("labels.png")}
                  value="0"
                  control={
                    <Radio disabled={disableFormImageWhenSelectAllImages} />
                  }
                />
                <FormControlLabel label="JPG" value="1" control={<Radio />} />
              </RadioGroup>
            </FormGroup>
            <FormGroup>
              <FormLabel>
                <h4>{t("labels.fileName")}</h4>
              </FormLabel>
              <RadioGroup name="group_image_naming" defaultValue="0">
                <FormControlLabel label="SKU" value="0" control={<Radio />} />
                <FormControlLabel label="UPC" value="1" control={<Radio />} />
                <FormControlLabel label="EAN" value="2" control={<Radio />} />
              </RadioGroup>
            </FormGroup>
            <>
              {ImageSizeForm(
                disableFormImageWhenSelectAllImages,
                selectedSize,
                setSelectedSize
              )}
            </>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FormGestioneImmaginiWeb;
